import * as React from "react"
import Layout from "../../components/layout"
import '../../style.css'
import Star from "../../components/star";
import DownloadButton from "../../components/downloadButton";

const AGPU = () => {
    const headerMarginValue = '30px'
    const ButtonStylesClass = 'BHRA-item-buttons'       
    const folder = '/downloads/resources/AGPU'

    return (
        <Layout>
            <div className="popup-container">
                <h1>Active Galaxy Pop-Up</h1>
                <Star />
                <p>This is a pop-up book that shows the nucleus of an active galaxy in three dimensions. It also includes a "Just-So" story entitled "How the Galaxy Got its Jets," the Tasty Active Galaxy Activity (described below), and a glossary.</p>
                <p>It was created for younger students (ages 8 - 12), though it can certainly be enjoyed by the older crowd!</p>
                <p>The pop-up Teacher's Guide can be downloaded here:</p>
                <DownloadButton text={"Teacher's Guide PDF"} link={`${folder}/Popguide07a.pdf`} buttonStyles = {ButtonStylesClass}/>
                <img style={{maxWidth:'100%' , width:'400px', alignSelf:'center'}} src='/popuplooksm.jpg' alt='alt'/> {/* Replace this with an image of Aurore*/}
                <p>The pop-up consists of three parts:</p>

                <ol>
                    <li>The pop-up part showing a 3-D active galaxy (as above) and all its elements. There is a brief description of each element provided on the same page, as well as a short glossary which supports the content.</li>
                    <li>An activity called "Tasty Galaxy." This is a short activity created to accompany the Active Galaxy Educators Unit, simplifying Activity 1 in the Educator's Guide in order to reach younger students. Students can make a model of the nucleus of an active galaxy out of edible materials, including a bagel (the dust torus), ice cream cones (the jets), and a donut hole (the black hole). Students enjoy seeing if they can eat the black hole before it eats them! This activity can be done in class or safely at home by children. It requires easy-to-find ingredients, and is accompanied by a simple step by step instruction. This activity offers both education and fun!</li>
                        <DownloadButton text={"Tasty Galaxy Activity"} link={`${folder}/tastyagnlitho06a.pdf`} buttonStyles = {ButtonStylesClass}/>
                    <li>A fiction story called "How the Galaxy Got Its Jets." This is an illustrated "Just-So" story about supermassive black holes. This story is a great asset to the reading collection of any classroom.</li>
                </ol>

                <h2 style={{margin: headerMarginValue}}>Readings</h2>
                <div className="BHRA-button-container">
                    <DownloadButton text={"Power Point Version in PDF format"} link={`${folder}/popstory.pdf`} buttonStyles = {ButtonStylesClass}/>
                    <DownloadButton text={"Accessible Pop-up Educator's Guide"} link={`${folder}/POPUPGuide0707508.pdf`} buttonStyles = {ButtonStylesClass}/>
                    <DownloadButton text={"Printable Booklet"} link={`${folder}/AGjetstory06.pdf`} buttonStyles = {ButtonStylesClass}/>
                    <DownloadButton text={"Printable Booklet - en español"} link={`${folder}/AGjetstory06.pdf`} buttonStyles = {ButtonStylesClass}/>
                </div>
                <p><i>Instructions for printing booklet - You will need three 8.5 by 11 sheets of paper. Set your printer to "landscape". Print all six pages of the PDF double-sided and stack all 3 sheets together so that the cover page "How The Galaxy Got Its Jets" and page "10" are on the outside facing "up". Fold the stack in half. Use staples for the binding along fold. Make sure you put the sheets in the correct order before you staple by checking the sequence of the page numbers.</i></p>
            
                <h2 style={{margin: headerMarginValue}}>Active Galaxy Pop-Up Text</h2>
                <h3>Active Galaxies</h3>
                <p>A galaxy is a spinning collection of stars, gas, and dust held together by gravity. A typical galaxy has billions of stars, and some have trillions of stars. Galaxies come in many different shapes, but what they are made of and how they are held together is the same for all of them. Most galaxies have a dense core of stars at the center. In the core the stars are very close together. In some galaxies, the core is so bright that it shines with the power of trillions of suns. These are called active galaxies. It is believed that a supermassive black hole is at the center of these galaxies. Active galaxies are so bright they can be seen clear across the visible universe. Orbiting around the core of the galaxies are millions of other stars and a lot of matter in the form of gas. The matter swirling around the black hole forms a disk of material that glows brilliantly because it is heated by friction and other forces inside the disk. Surrounding the disk is a doughnut-shaped ring of gas and dust called the torus. The torus is very thick. The inside part glows brightly because it is heated by the matter around the black hole. But because it's so thick, it's cold and dark on the outside. In some active galaxies, jets of very hot gas move away from the core on either side of the galaxy at extremely high speeds, almost at the speed of light. These jets look like long, narrow tubes or cones that are perpendicular to the disk. The jets eventually slow to a stop because of friction with gas that lies outside the galaxy. When this happens, the jets billow out to form giant clouds of matter.</p>
                <h3 style={{margin: headerMarginValue}}>Glossary</h3>
                <ul>
                    <li><b>Active Galaxy:</b> a galaxy with a super-massive black hole at its center that is sending out huge amounts of energy from a tiny core region.</li>
                    <li><b>Black Hole:</b> an object so small and dense (tightly packed together) and with gravity so strong that nothing, not even light can escape it.</li>
                    <li><b>Core: </b> the central part of a galaxy. Most galaxies have a supermassive black hole in their cores.</li>
                    <li><b>Galaxy:</b> a giant collection of stars, gas, and dust held together by their own gravity.</li>
                    <li><b>Jets: </b> thin beams of matter and energy that look like long, narrow tubes blown out of the center of an active galaxy.</li>
                    <li><b>Supermassive black hole:</b> a black hole that has millions or billions of times the Sun's mass.</li>
                    <li><b>Torus:</b> a doughnut-shaped object. In an active galaxy, a doughnut-shaped ring of gas and dust orbit the central black hole.</li>
                </ul>


                <h2 style={{margin: headerMarginValue}}>Active Galaxy Pop-Up Education Standards</h2>
                <a href="https://nap.nationalacademies.org/catalog/4962/national-science-education-standards#toc" target='_blank' rel="noreferrer">National Science Education Standards</a>
                
                <p><b>Content Standard: K-12</b></p>
                <p>Unifying Concepts and Processes</p>
                <p>STANDARD: As a result of activities in grades K-12, all students should develop understanding and abilities aligned with the following concepts and processes:</p>
                <ul>
                    <li>Systems, order, and organization</li>
                    <li>Evidence, models, and explanation</li>
                </ul>

                <p><b>Content Standards 9-12</b></p>
                <p>Earth and Space Science</p>
                
                <p><b>Content Standards D:</b></p>
                <ul>
                    <li>Origin and evolution of the universe</li>
                </ul>

                <p><a href='https://ncte.org/statement/standards/' target='_blank' rel="noreferrer">Standards for the English Language Arts</a></p>

                <p><b>NL-ENG.K-12.1 Reading for Perspective</b></p>
                <p>Students read a wide range of print and non-print texts to build an understanding of texts, of themselves, and of the cultures of the United States and the world; to acquire new information; to respond to the needs and demands of society and the workplace; and for personal fulfillment. Among these texts are fiction and nonfiction, classic and contemporary works.</p>
            
                <p><b>NL-ENG.K-12.6 Applying Knowledge</b></p>
                <p>Students apply knowledge of language structure, language conventions (e.g., spelling and punctuation), media techniques, figurative language, and genre to create, critique, and discuss print and non-print texts.</p>

                <p><b>NL-ENG.K-12.7 Evaluating Data</b></p>
                <p>Students conduct research on issues and interests by generating ideas and questions, and by posing problems. They gather, evaluate, and synthesize data from a variety of sources (e.g., print and non-print texts, artifacts, people) to communicate their discoveries in ways that suit their purpose and audience.</p>
                
                <p><b>NL-ENG.K-12.8 Developing Research Skills</b></p>
                <p>Students use a variety of technological and information resources (e.g., libraries, databases, computer networks, video) to gather and synthesize information and to create and communicate knowledge.</p>
                
                <p><b>NL-ENG.K-12.11 Participating in Society</b></p>
                <p>Students participate as knowledgeable, reflective, creative, and critical members of a variety of literacy communities.</p>
                
                <p><b>NL-ENG.K-12.12 Applying Language Skills</b></p>
                <p>Students use spoken, written, and visual language to accomplish their own purposes (e.g., for learning, enjoyment, persuasion, and the exchange of information).</p>
            </div>
        </Layout>
  )
}

export default AGPU